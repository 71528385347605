// import getcaptcha from "./utils/getcaptcha";

export default function FormLoading(el) {
    const message = el.dataset.message || "";

    function showLoader(e) {
        e.preventDefault();
        el.classList.add("transition-all", "relative");

        const loader = document.createElement("div");
        loader.classList.add(
                    "absolute",
                    "inset-0",
                    "flex",
                    "flex-col",
                    "items-center",
                    "justify-center",
                    "bg-white",
                    "bg-opacity-70",
                    "z-10");

        loader.innerHTML = `<svg style='width: 100px' version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                <path fill="#F96219" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform>
            </path>
            </svg>
            <p class='p-5'>${message}</p>`;

        el.appendChild(loader);

        const errors = el.dataset.errors;

        if (errors && errors === 'false') {
            // errors attr exists and is false, submit the form
            el.submit();
        } else if (!errors) {
            // error attr does not exist, submit the form as normal
            el.submit();
        } else {
            // error attr exists and is true, remove the loader
            el.removeChild(loader);
        }

        // getcaptcha().then((token) => {
        //   el.querySelectorAll(".reCaptchaToken").forEach(
        //     (el) =>
        //       (el.outerHTML = `<input type="hidden" name="reCaptchaToken" value="${token}" />`)
        //   );
        // });
    }

    el.addEventListener("submit", showLoader);
}
