var _globalScoreConclusions = [
  "Little to no evidence of a focus on customer experience", // 0 to 20
  "Approach to CX is immature immediate action is required", // 20 to 35
  "Evidence of a focus on CX evident but it is not structured or sustainable", // 35 to 45
  "An intentional approach to CX has been established, but not yet systematic", // 45 to 60
  "Some, but not all, CX competencies are being managed systematically", // 60 to 80
  "A systematic approach to CX is evident and sustainable", // 80 to 100
];

export default function Assessment(el) {
  console.log(el);
  const startForm = el.querySelector("form");
  const testForm = el.querySelector(".cxq-container");
  const testSubmit = el.querySelector(".cxq-container button[type='submit']");
  const scoreForm = el.querySelector(".assm-score");
  const score = {};
  let leadId = "";
  let csrf = "";

  startForm.addEventListener("submit", (e) => {
    e.preventDefault();

    const startFormData = new FormData();

    [...startForm.querySelectorAll("input")].forEach((inp) =>
      startFormData.append(inp.name, inp.value)
    );

    startFormData.delete("confirm");
    const submit = startForm.querySelector('[type="submit"]');
    submit.disabled = true;
    submit.innerHTML = "<span>Please wait...</span>";

    fetch(
      location.origin + "/actions/cemantica-api/default/cx-assessment-lead",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: startFormData,
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json(); // Parse the JSON response if the request was successful
        } else if (response.status >= 400 && response.status < 500) {
          // Handle client errors (e.g., 400 Bad Request, 404 Not Found)
          return response.json().then((data) => {
            throw new Error(
              `Client error (${response.status}): ${JSON.stringify(data)}`
            );
          });
        } else if (response.status >= 500) {
          // Handle server errors (e.g., 500 Internal Server Error)
          throw new Error(
            `Server error (${response.status}): ${response.statusText}`
          );
        } else {
          // Handle other types of errors
          throw new Error(
            `Unexpected response status (${response.status}): ${response.statusText}`
          );
        }
      })
      .then((data) => {
        console.log("Response data:", data);
        leadId = data.leadId;
        csrf = data.csrf;

        startForm.style.display = "none";
        testForm.style.display = "block";
      })
      .catch((error) => {
        console.error("Error:", error.message);
        // Optionally, handle the display of error messages in the UI here
      });
  });

  document.querySelectorAll(".ratebar").forEach((rb) => {
    rb.querySelectorAll("li").forEach((li, i, allLi) =>
      li.addEventListener("click", () => {
        allLi.forEach((li) => li.classList.remove("selected"));
        li.classList.add("selected");
        collectScore();
      })
    );
  });

  function collectScore() {
    document.querySelectorAll(".ratebar li.selected").forEach((li) => {
      const name = li.parentElement.id;
      score[name] = parseInt(li.textContent, 10);
    });

    return score;
  }

  testSubmit.addEventListener("click", (e) => {
    e.preventDefault();
    testForm.style.display = "none";
    scoreForm.style.display = "block";

    // Collect scores
    const scoreData = collectScore();
    console.log("Collected score: ", scoreData);

    fetch("/actions/snaptcha/field/get-field", {
      headers: {
        Accept: "application/json",
      },
    })
      .then((result) => result.json())
      .then((result) => {
        // Construct the payload object
        const payload = {
          ...scoreData, // Spread collected score data into the payload
          leadId: leadId,
          CRAFT_CSRF_TOKEN: csrf,
          snaptcha: result.value, // Use the collected 'snaptcha' value
        };

        // Convert the payload object to a JSON string
        const scoreFormData = JSON.stringify(payload);

        // Use 'jsonPayload' as the body in the fetch request
        fetch(
          location.origin +
            "/actions/cemantica-api/default/cx-assessment-summary",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: scoreFormData, // Use the JSON string here
          }
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("Server response: ", data);
            // Handle success, such as updating the UI or displaying a message
          })
          .catch((error) => {
            console.error("Error submitting score: ", error);
            // Handle error, such as displaying an error message to the user
          });
      });

    const calculatedScore = Object.values(score).reduce((a, b) => a + b);

    document.getElementById("CxAssessmentGlobalScore").style =
      "width:" + calculatedScore + "%;";
    document.getElementById("calculated-score").innerHTML = calculatedScore;
    var conclusion = "";
    if (calculatedScore > 80 && calculatedScore <= 100)
      conclusion = _globalScoreConclusions[5];
    else if (calculatedScore > 60 && calculatedScore <= 80)
      conclusion = _globalScoreConclusions[4];
    else if (calculatedScore > 45 && calculatedScore <= 60)
      conclusion = _globalScoreConclusions[3];
    else if (calculatedScore > 35 && calculatedScore <= 45)
      conclusion = _globalScoreConclusions[2];
    else if (calculatedScore > 20 && calculatedScore <= 35)
      conclusion = _globalScoreConclusions[1];
    else if (calculatedScore >= 0 && calculatedScore <= 20)
      conclusion = _globalScoreConclusions[0];
    document.getElementById("CxAssessmentGlobalScoreConclusion").innerHTML =
      conclusion;
    //   $(".assm-score").show();
    document.querySelector(".assm-score").style.display = "block";
    location.href = "#myscore";
  });
}
