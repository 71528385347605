// import events from "./utils/events";

export default function Form(el) {
  console.log('form');
  const isForgotForm = document.querySelector("#forgotForm");
  const siteUrl = window?.siteUrl ?? "/";

  const error = el.querySelector("[data-errors]");
  const submit = el.querySelector('[type="submit"]');
  const success = document.querySelector(el.dataset?.success);

  // if (isForgotForm !== null) {
  //   console.log('forgot form');
  //   // Specific logic for the forgot password form
  //   el.addEventListener("submit", (e) => {
  //     e.preventDefault();
  //     submit.setAttribute("disabled", true);
  //     submit.querySelector("span").innerText = "Sending...";

  //     fetch(siteUrl, {
  //       method: "post",
  //       body: new FormData(el),
  //       headers: {
  //         Accept: "application/json",
  //       },
  //     })
  //     .then(response => response.json()) // Parse JSON response
  //     .then(data => {
  //       if (data.success) {
  //         showSuccess();
  //       } else {
  //         showErrors(data.errors || {});
  //       }
  //     })
  //     .catch((e) => {
  //       console.error('Fetch error:', e);
  //       error.innerHTML = "⚠️ Something went wrong. Please try again.";
  //       submit.removeAttribute("disabled");
  //       submit.querySelector("span").innerText = "Submit";
  //     });
  //   });
  // } else {

    el.setAttribute("method", "POST");
    const actionEl = document.createElement("input");
    actionEl.setAttribute("type", "hidden");
    actionEl.name = "action";
    actionEl.value = "contact-form/send";
    el.append(actionEl);

    el.addEventListener("submit", (e) => {
      e.preventDefault();
      submit.setAttribute("disabled", true);
      submit.querySelector("span").innerText = "Sending...";

      fetch(siteUrl, {
        method: "post",
        body: new FormData(el),
        headers: {
          Accept: "application/json",
        },
      })
        .then((r) => {
          if (r.status == 200) showSuccess();
          else showErrors(data.errors);
        })
        .then((data) => {
          console.log(data);
        })
        .catch((e) => {
          error.innerHTML = "⚠️ Something went wrong please try again.";
          submit.removeAttribute("disabled");
          submit.querySelector("span").innerText = "Submit";
        });
    });
  // }

  function showSuccess() {
    el.classList.add("hidden");
    success.classList.remove("hidden");
    console.log("success"); 
  }

  function showErrors(errors) {
    console.log(errors);
    let msg = "";
    for (const error in errors) {
      msg += `⚠️ ${errors[error]}<br/>`;
    }
    error.innerHTML = msg;

    submit.removeAttribute("disabled");
    submit.querySelector("span").innerText = "Submit";
  }

  return () => {};
}
