import { render, Fragment } from "preact";
import { useState } from "preact/hooks";
import clsx from "clsx";
import PriceCard from "./Components/PriceCard";

function PricingCardsComponent({ data, craft, loggedin }) {
  const [term, setTerm] = useState(data[0].billingFrequency);
  const [currencyCode, setCurrencyCode] = useState("USD");

  const currencies = data
    .map((plan) => plan.currency)
    .filter((v, i, self) => self.indexOf(v) === i);

  const current = +new URLSearchParams(location.search).get("current");
  console.log(data);

  return (
    <>
      <div class="flex items-center justify-center">
        <span>Monthly</span>
        <label class="switch mx-5 md:mx-10">
          <input
            class="rounded-full"
            type="checkbox"
            onChange={(e) => {
              setTerm(
                data.find((plan) => plan.billingFrequency != term)
                  .billingFrequency
              );
            }}
          />
          <span class="rounded-full slider-switch round"></span>
        </label>
        <span>Yearly</span>
        <p class="grid items-center bg-orange mb-0 leading-3 h-[32px] text-[12px] px-4 ml-5 text-white">
          10% Discount
        </p>
      </div>
      <div className="flex justify-center mt-10">
        <div class="inline-flex divide-x divide-orange border border-orange rounded-xl text-orange shadow shadow-orange font-bold overflow-hidden">
          {currencies.map((c) => (
            <button
              key={c}
              onClick={(e) => {
                setCurrencyCode(c);
              }}
              class={clsx(
                "py-2 px-3",
                c == currencyCode && "bg-orange text-white"
              )}
            >
              {c}
            </button>
          ))}
        </div>
      </div>

      <div className="flex flex-col xl:flex-row items-center xl:items-stretch justify-center mt-10 xl:mt-20 xl:space-x-5">
        {data
          .filter(
            (plan) =>
              plan.currency == currencyCode && plan.billingFrequency == term
          )
          .map((plan, i) => {
            {
              /* console.log(plan.price);
        let updatedSubscriptionName = plan.subscriptionName;
        if (updatedSubscriptionName === 'Standard') {
          updatedSubscriptionName = 'Journey Mapping';
        }

        console.log(updatedSubscriptionName); */
            }
            return (
              <PriceCard
                hideBuyButton={i < current}
                key={i}
                i={i + 1}
                price={plan.price}
                plans={data}
                term={term}
                subscriptionName={plan.subscriptionName}
                currencyCode={currencyCode}
                loggedin={loggedin}
                craft={craft.find((c) =>
                  c.heading
                    .toLowerCase()
                    .trim()
                    .includes(plan.subscriptionName.toLowerCase().trim())
                )}
              />
            );
          })}
      </div>
    </>
  );
}

export default function PricingCards(el) {
  console.log(
    "Pricing data:",
    el.dataset.pricing ? JSON.parse(el.dataset.pricing) : "No pricing data"
  );
  console.log(
    "Craft data:",
    el.dataset.craft ? JSON.parse(el.dataset.craft) : "No craft data"
  );
  console.log(
    "Logged in data:",
    el.dataset.loggedin ? JSON.parse(el.dataset.loggedin) : "No loggedin data"
  );

  // Parse the pricing data and update the subscription name
  let pricingData = el.dataset.pricing
    ? JSON.parse(el.dataset.pricing).map((plan) => {
        if (plan.subscriptionName === "Standard") {
          // plan.subscriptionName = 'Journey Mapping';
          // plan.price = 0;
        }
        return plan;
      })
    : null;

  let craftData = el.dataset.craft
    ? JSON.parse(el.dataset.craft).map((plan) => {
        if (plan.heading === "Standard") {
          // plan.heading = 'Journey Mapping';
        }
        return plan;
      })
    : null;
  // let pricingData = el.dataset.pricing ? JSON.parse(el.dataset.pricing).map(plan => {
  //   if (plan.subscriptionName === 'Standard') {
  //     plan.subscriptionName = 'Journey Mapping';
  //   }
  //   return plan;
  // }) : null;

  const App = (
    <PricingCardsComponent
      data={pricingData}
      craft={craftData}
      loggedin={el.dataset.loggedin ? JSON.parse(el.dataset.loggedin) : null}
    />
  );
  render(App, el);
}
